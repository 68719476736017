html, body, #root {
	height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
	font-family: 'Moon 2.0 Regular';
	font-style: normal;
	font-weight: normal;
	src: local('Moon 2.0 Regular'), url(./fonts/Moon2.0-Regular.woff) format('woff');
}


@font-face {
	font-family: 'Moon 2.0 Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Moon 2.0 Bold'), url(./fonts/Moon2.0-Bold.woff) format('woff');
}


@font-face {
	font-family: 'Moon 2.0 Light';
	font-style: normal;
	font-weight: normal;
	src: local('Moon 2.0 Light'), url(./fonts/Moon2.0-Light.woff) format('woff');
}
